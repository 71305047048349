import { Divider } from "@mui/material";
import { X } from "components/oldDesignAssets/icons";
import toast from "toasted-notes";
import Price from "../global/price";
import { Warning } from "./icons";

const actionList = {
  cancel: { title: "Confirm Cancellation?", button: "Okay" },
  confirm: { title: "Service Change Alert" },
  delete: { title: "Delete Payment Method?", button: "Okay" },
  accepted: { title: "Confirm State Change" },
  assigned: { title: "Confirm State Change" },
  inTransit: { title: "Confirm State Change" },
  inProgress: { title: "Confirm State Change" },
  visitEnd: { title: "Confirm State Change" },
  completed: { title: "Confirm Grand Total?" },
  createUserSMS: { title: "Confirm Update User" },
  createUser: { title: "Confirm Update User" },
  updateUser: { title: "Confirm Update User" },
  createPatient: { title: "Confirm Update Patient" },
  updatePatient: { title: "Confirm Update Patient" },
  sendAppLinkSMS: { title: "Confirm Send Notification" },
  sendResetPassword: { title: "Confirm Send Notification" },
  resendBookingConfirmationSMS: { title: "Confirm Send Notification" },
  resendBookingConfirmationEmail: { title: "Confirm Send Notification" },
  bookingWithConfirmation: { title: "Confirm Booking" },
  booking: { title: "Confirm Booking" },
  deleteTimeBlock: { title: "Confirm Delete" },
  deleteTask: { title: "Confirm Delete" },
  removePaymentCollection: { title: "Confirm Delete Record" },
  unSavedBack: { title: "Confirm Back" },
  assignProvider: { title: "Assign Task", button: "Yes, Assign" },
  confirmChargeUser: { title: "Confirm Charge User" },
  confirmCustomAmount: { title: "Billing Confirmation", button: "Proceed" },
};

const NotificationModal = ({ callback, action, position = "top", onCancel, data }) => {
  let buttonCallbackDisabled = false;
  let buttonCancelDisabled = false;

  const onClickButtonCallBack = e => {
    if (!buttonCallbackDisabled) {
      callback(e);
      buttonCallbackDisabled = true;
    }
    return true;
  };

  const onClickButtonCancel = e => {
    if (onCancel && !buttonCancelDisabled) {
      onCancel(e);
      buttonCancelDisabled = true;
    }
    return true;
  };

  const descriptionList = {
    cancel: "Are you sure you want to cancel the request?",
    confirm: "Are you sure you want to change the service? Some information may be lost.",
    delete: "Are you sure you want to delete this payment method?",
    accepted: (
      <>
        Are you sure you want to change state to <span>RECEIVED</span>?
      </>
    ),
    assigned: (
      <>
        Are you sure you want to change state to <span>ASSIGNED</span>?
      </>
    ),
    inTransit: (
      <>
        Are you sure you want to change state to <span>IN TRANSIT</span>?
      </>
    ),
    inProgress: (
      <>
        Are you sure you want to change state to <span>VISIT START</span>?
      </>
    ),
    visitEnd: (
      <>
        You are about to change the status from "<b>Completed</b>" to "<b>Visit End</b>".
        <br />
        <br /> Are you sure want to proceed?
      </>
    ),
    completed: (
      <div>
        You have input
        <b>
          <Price value={data?.grand} string postfix=" " prefix=" " />
        </b>
        as the Grand Total for <b>S-{data?.id}</b>. Upon confirming, this amount cannot be changed and User will be
        billed this amount.
        <br />
        <br />
        Do you wish to proceed?
      </div>
    ),
    createUserSMS: "Please ensure User information is correct. An SMS will be sent to the user to download the App.",
    createUser: "Please ensure User information is correct.",
    updateUser: "Please ensure User information is correct.",
    createPatient: "Please ensure Patient information is correct.",
    updatePatient: "Please ensure Patient information is correct.",
    sendAppLinkSMS: `User will be sent a Download App Link via SMS to ${data?.ext}${data?.number}`,
    sendResetPassword: `User will be sent a Reset Password via Email to ${data}`,
    resendBookingConfirmationSMS: `User will be sent a Booking Confirmation via SMS to ${data?.ext}${data?.number}`,
    resendBookingConfirmationEmail: `User will be sent a Booking Confirmation via Email to ${data}`,
    bookingWithConfirmation: (
      <>
        Booking confirmation will be sent to user via {data?.method} to{" "}
        {data?.method === "SMS" ? `${data?.phoneNumber?.ext}${data?.phoneNumber?.number}` : data?.email}.
        {data?.numberOfAppointments && (
          <>
            <Divider sx={{ borderColor: "#EEEEEE", mt: 3, mb: 2 }} />
            You are about to schedule {data?.numberOfAppointments} appointments.
            <br />
            <br />
            Would you like to proceed?
          </>
        )}
      </>
    ),
    assignProvider: (
      <>
        Are you sure want to assign this{" "}
        <b>
          S-{data?.serviceId} {data?.serviceType}
        </b>{" "}
        task to {data?.name} ?
      </>
    ),
    booking: (
      <>
        <Warning />
        No Notifications selected
        {data?.numberOfAppointments && (
          <>
            <Divider sx={{ borderColor: "#EEEEEE", my: 2 }} />
            You are about to schedule {data?.numberOfAppointments} appointments.
            <br />
            <br />
            Would you like to proceed?
          </>
        )}
      </>
    ),
    deleteTimeBlock: (
      <>
        You are about to delete the{" "}
        <span>
          {data?.startTime}-{data?.endTime}
        </span>{" "}
        time block for <span>{data?.name}</span>.
        <br />
        <br />
        Do you wish to proceed?
      </>
    ),
    deleteTask: (
      <>
        You are about to delete the{" "}
        <span>
          {data?.tag} {data?.startTime}-{data?.endTime}
        </span>{" "}
        slot for <span>{data?.name}</span>.
        <br />
        <br />
        Do you wish to proceed?
      </>
    ),
    removePaymentCollection: (
      <>
        This will permanently delete the cash collection record.
        <br />
        <br />
        Do you wish to proceed?
      </>
    ),
    unSavedBack: (
      <>
        There are unsaved changes.
        <br />
        <br />
        Are you sure you want to go back?
      </>
    ),
    confirmChargeUser: (
      <>
        You are about to charge the user{" "}
        <b>
          <Price value={data?.amount} string postfix=" " prefix=" " />
        </b>
        .
        <br />
        <br />
        Do you wish to proceed?
      </>
    ),
    confirmCustomAmount: (
      <>
        Invoice Amount:{" "}
        <b>
          <Price value={data?.billedAmount} string postfix=" " prefix=" " />
        </b>
        .
        <br />
        <br />
        Are you sure you want to proceed with charging the user{" "}
        <b>
          <Price value={data?.customAmount} string postfix=" " prefix=" " />
        </b>
        ?
        <br />
        Please confirm be selecting 'Proceed' or 'Cancel' below.
      </>
    ),
  };

  toast.notify(
    ({ onClose }) => {
      return (
        <div className="toasterConfirm notificationModal" data-testid="confirmModal">
          <div className="Toaster__backdrop" />
          <div className="content">
            <X className="close" onClick={onClose} />
            <div className="title">{actionList[action]?.title || "Confirm?"}</div>
            <div className="description">{descriptionList[action]}</div>
            <div className="actionButtons">
              <span
                className="linkRed"
                onClick={e => onClickButtonCancel(e) && onClose(e)}
                style={{ cursor: "pointer" }}>
                Cancel
              </span>
              <button
                type="button"
                data-testid="confirmButton"
                className="primary"
                onClick={e => onClickButtonCallBack(e) && onClose(e)}>
                {actionList[action]?.button || "Confirm"}
              </button>
            </div>
          </div>
        </div>
      );
    },
    {
      duration: null,
      position,
    },
  );
};

export default NotificationModal;
