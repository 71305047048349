import { createTheme, PaletteColor, PaletteColorOptions, Shadows, TypeText } from "@mui/material";

const designSystem = {
  shadows: {
    1: "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
    2: "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    3: "0px 2px 6px rgba(0, 0, 0, 0.1)",
  },
  colors: {
    common: {
      offBlack: "#0D1119",
      white: "#FFFFFF",
    },
    primary: {
      main: "#222732",
      light: "#4E525B",
      dark: "#171B23",
    },
    secondary: {
      main: "#00C3B5",
      light: "#33CFC3",
      dark: "#00887E",
    },
    text: {
      primary: "#222732",
      secondary: "#868E95",
      disabled: "#D3D6DB",
    },
    icon: {
      main: "#A5AFB7",
      dark: "#444A50",
      disabled: "#E0E2E4",
    },
    divider: {
      primary: "#EFF2F6",
      secondary: "#E7EAEE",
    },
    system: {
      wildTide: { 50: "#E8EEFD", 300: "#7297F3", 500: "#144FE7", 700: "#0C318D", 800: "#08205E" },
      newEmerald: { 50: "#E8FCF6", 300: "#75F0CB", 500: "#16C793", 700: "#0F8A65", 800: "#0A5C43" },
      warningOrange: { 50: "#FFF3E5", 300: "#FFB866", 500: "#FF981F", 700: "#995200", 800: "#663600" },
      errorRed: { 50: "#FFE5EB", 300: "#FF6685", 500: "#FF1F4A", 700: "#99001F", 800: "#660014" },
      successGreen: { 50: "#EAFBF3", 300: "#7EE7B6", 500: "#20AC6B", 700: "#188150", 800: "#105635" },
      yellow: { 50: "#FFFBE5", 300: "#FFE666", 500: "#FFDD29", 700: "#998000", 800: "#665500" },
      purple: { 50: "#F5E8FD", 300: "#C272F3", 500: "#9714E7", 700: "#5C0C8D", 800: "#3D085E" },
      cyan: { 50: "#E8FCFC", 300: "#76EFEF", 500: "#78EFEF", 700: "#108989", 800: "#0B5B5B" },
    },
  },
};

export const muiTheme = (() => {
  const defaultTheme = createTheme({});
  const newShadows: Shadows = [...defaultTheme.shadows];
  for (const key in designSystem.shadows) {
    newShadows[key] = designSystem.shadows[key];
  }

  return createTheme({
    components: {
      MuiButton: {
        defaultProps: { disableElevation: true },
        styleOverrides: {
          root: {
            minHeight: "40px",
            minWidth: "80px",
          },
          startIcon: {
            ".MuiSvgIcon-root": { fontSize: 20 },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            minHeight: "40px",
            fontSize: "14px",
            "& fieldset": {
              borderColor: "#A5AFB7",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: { borderRadius: "4px" },
        },
      },
    },
    typography: {
      fontFamily: "Mulish, 'Open Sans', Roboto, sans-serif",
      button: { fontWeight: 700, textTransform: "capitalize" },
    },
    shape: {
      borderRadius: 6,
    },
    shadows: newShadows,
    palette: {
      primary: {
        main: designSystem.colors.primary.main,
        contrastText: designSystem.colors.common.white,
      },
      secondary: {
        main: designSystem.colors.secondary.main,
        contrastText: designSystem.colors.common.white,
      },
      error: {
        main: designSystem.colors.system.errorRed[500],
      },
      warning: {
        main: designSystem.colors.system.warningOrange[500],
      },
      success: {
        main: designSystem.colors.system.newEmerald[500],
      },
      text: {
        primary: designSystem.colors.primary.main,
        secondary: designSystem.colors.text.secondary,
        disabled: designSystem.colors.text.disabled,
      },
      icon: {
        main: designSystem.colors.icon.main,
        dark: designSystem.colors.icon.dark,
        light: designSystem.colors.icon.disabled,
      },
      action: {
        disabledBackground: designSystem.colors.text.disabled,
        disabled: designSystem.colors.text.secondary,
      },

      componentTextSecondary: {
        main: designSystem.colors.text.secondary,
      },
      componentSectionBackgroundGrey: {
        main: "#FCFCFC",
        contrastText: designSystem.colors.primary.main,
      },
      componentDividerPrimary: {
        main: designSystem.colors.divider.primary,
        contrastText: designSystem.colors.primary.main,
      },
      componentDividerSecondary: {
        main: designSystem.colors.divider.secondary,
        contrastText: designSystem.colors.primary.main,
      },
      componentIconMain: {
        main: designSystem.colors.icon.main,
        contrastText: designSystem.colors.text.secondary,
      },
      componentIconDisabled: {
        main: designSystem.colors.text.disabled,
        contrastText: designSystem.colors.primary.main,
      },
      componentNewEmerald50: {
        main: designSystem.colors.system.newEmerald[50],
        contrastText: designSystem.colors.secondary.main,
      },
      componentWildTide50: {
        main: designSystem.colors.system.wildTide[50],
        contrastText: designSystem.colors.system.wildTide[500],
      },
      componentWildTide500: {
        main: designSystem.colors.system.wildTide[500],
        contrastText: designSystem.colors.common.white,
      },
      componentPurple50: {
        main: designSystem.colors.system.purple[50],
        contrastText: designSystem.colors.system.purple[500],
      },
      componentPurple500: {
        main: designSystem.colors.system.purple[500],
        contrastText: designSystem.colors.secondary.main,
      },
      componentSuccessGreen50: {
        main: designSystem.colors.system.successGreen[50],
        contrastText: designSystem.colors.system.successGreen[500],
      },
      componentErrorRed50: {
        main: designSystem.colors.system.errorRed[50],
        contrastText: designSystem.colors.system.errorRed[500],
      },

      jarvisDivider: {
        primary: designSystem.colors.divider.primary,
        secondary: designSystem.colors.divider.secondary,
      },
      jarvisGrey: {
        1: "#F9FAFC",
      },

      ...designSystem.colors.system,
    },
  });
})();

interface LeveledColorOptions {
  1: string;
}

declare module "@mui/material/styles" {
  interface PaletteOptions {
    icon: PaletteColorOptions;
    componentTextSecondary: PaletteColorOptions;
    componentSectionBackgroundGrey: PaletteColorOptions;
    componentDividerPrimary: PaletteColorOptions;
    componentDividerSecondary: PaletteColorOptions;
    componentIconMain: PaletteColorOptions;
    componentIconDisabled: PaletteColorOptions;
    componentNewEmerald50: PaletteColorOptions;
    componentWildTide50: PaletteColorOptions;
    componentWildTide500: PaletteColorOptions;
    componentPurple50: PaletteColorOptions;
    componentPurple500: PaletteColorOptions;
    componentSuccessGreen50: PaletteColorOptions;
    componentErrorRed50: PaletteColorOptions;
    jarvisDivider: Partial<TypeText>;
    jarvisGrey: LeveledColorOptions;
  }

  interface Palette {
    icon: PaletteColor;
    componentTextSecondary: PaletteColor;
    componentSectionBackgroundGrey: PaletteColor;
    componentDividerPrimary: PaletteColor;
    componentDividerSecondary: PaletteColor;
    componentIconMain: PaletteColor;
    componentIconDisabled: PaletteColor;
    componentNewEmerald50: PaletteColor;
    componentWildTide50: PaletteColor;
    componentWildTide500: PaletteColor;
    componentPurple50: PaletteColor;
    componentPurple500: PaletteColor;
    componentSuccessGreen50: PaletteColor;
    componentErrorRed50: PaletteColor;
    jarvisDivider: TypeText;
    jarvisGrey: LeveledColorOptions;
  }
}

declare module "@mui/material" {
  interface IconButtonPropsColorOverrides {
    icon: any;
  }

  interface ButtonPropsColorOverrides {
    componentDividerPrimary: any;
    componentDividerSecondary: any;
    componentNewEmerald50: any;
    componentWildTide500: any;
    componentTextSecondary: any;
  }

  interface ChipPropsColorOverrides {
    componentWildTide50: any;
    componentWildTide500: any;
  }
}
