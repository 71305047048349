import { FileAdd, X } from "components/oldDesignAssets/icons";
import { useState } from "react";
import escape from "regex-escape";
import { requestStore } from "../../../stores/modalManager";
import Header from "../header";
import { ArrowLeft } from "../icons";
import Input from "../input";
import TagInfo from "../tagInfo";
import CreateRequest from "./createRequest";
import EditRequest from "./editRequest";

export default function AddonList() {
  const addonItems = requestStore.useState(s => s.addonItems);
  const request = requestStore.useState(s => ({
    ...s.request,
    ...s.unsavedChanges,
  }));
  const [filter, setFilter] = useState("");

  const isAmbulanceDelivery = request.type === "Delivery" && request.specialty === "Ambulance";
  const isBabyVaccinationCase = request.case.type === "Baby Vaccination";

  const addonTags = [];
  if (isAmbulanceDelivery) addonTags.push("ambulance");
  if (isBabyVaccinationCase) addonTags.push("baby vaccination");
  if (request.case.type === "B2C") addonTags.push("b2c");

  const selectedAddonItems = request.items.filter(item => {
    return item.item.tags.includes("addons") && item.item.tags.find(t => addonTags.includes(t));
  });
  const availableAddonItems = addonItems.filter(
    item =>
      item.tags.find(t => addonTags.includes(t)) &&
      !selectedAddonItems.find(i => i.item._id === item._id) &&
      new RegExp(escape(`${filter}`), "gi").test(`${item.title}`),
  );

  const goBack = e => {
    e.preventDefault();
    requestStore.update(s => {
      if (s.request && s.request.status !== "draft") s.componentsToRender = <EditRequest />;
      else s.componentsToRender = <CreateRequest />;
    });
  };

  const onClickSelectedItem = idToBeRemoved => {
    requestStore.update(s => {
      s.unsavedChanges.items = request.items.filter(item => item.item._id !== idToBeRemoved);
      s.unsavedChanges.isDirty.items = true;
    });
  };

  const onClickAddonItem = itemToBeAdded => {
    requestStore.update(s => {
      s.unsavedChanges.items = [...request.items, { quantity: 1, item: itemToBeAdded }];
      s.unsavedChanges.isDirty.items = true;
    });
  };

  return (
    <div className="modalContent addonListUM">
      <Header>
        <ArrowLeft onClick={goBack} />
        <div className="title">Additional Requirements</div>
      </Header>

      <div className="content">
        <Input value={filter} icon={<FileAdd />} onChange={setFilter} placeholder="Search Add-ons" />

        <div className="selectedAddonContainer">
          {selectedAddonItems.map((item, i) => (
            <TagInfo
              key={i}
              title={item.item.title}
              subtitle={item.item.description}
              action={<X onClick={() => onClickSelectedItem(item.item._id)} />}
            />
          ))}
        </div>

        <div className="addonListContainer">
          {availableAddonItems.map((item, i) => (
            <div key={i} className="addonItem" onClick={() => onClickAddonItem(item)}>
              <div className="addonTitle">{item.title}</div>
              <div className="addonDescription">{item.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
