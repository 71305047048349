import URI from "urijs";
import http from "./httpService";

export function assignRequest(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/assign`, data);
}

export function getRequest(id) {
  return http.get(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}`);
}

export function getDraft(id, data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/draft/${id}`);
  uri.addSearch(data);
  return http.get(uri.toString());
}

export function getServicesDraft(data, abortController) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/services/draft`);
  uri.addSearch(data);
  return http.get(uri.toString(), { signal: abortController.signal });
}

export function editDraft(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/draft/${id}`, data);
}

export function createRequestFromDraft(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/draft`, data);
}

export function cancelDraft(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/draft/${id}/cancel`, data);
}

export function dischargeRequest(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/discharge`);
}

export function transitRequest(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/transit`);
}

export function progressRequest(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/progress`);
}

export function cancelRequest(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/cancel`, data);
}

export function visitEndRequest(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/visitEnd`);
}

export function completeRequest(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/complete`, data);
}

export function completeDeliveryRequest(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/delivery/${id}/complete`);
}

export function triageRequest(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/confirm`, data);
}

export function confirmCancellation(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/cancel/confirm`, data);
}

export function createDeliveryService(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/delivery`, data);
}

export function editSchedule(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/schedule`, data);
}

export function editSymptoms(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/symptom`, data);
}

export function createRequest(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service`, data);
}

export function editPayment(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/payment`, data);
}

export function editBusiness(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/business`, data);
}

export function editClinicalNotes(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/clinicalNotes`, data);
}

export function editItems(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/items`, data);
}

export function editJarvisNotes(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/jarvisNotes`, data);
}

export function getServices(data, abortController) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/services`);
  uri.addSearch(data);
  return http.get(uri.toString(), { signal: abortController.signal });
}

export function getServicesAccepted(data, abortController) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/services/accepted`);
  uri.addSearch(data);
  return http.get(uri.toString(), { signal: abortController.signal });
}

export function getServicesCancelled(data, abortController) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/services/cancelled`);
  uri.addSearch(data);
  return http.get(uri.toString(), { signal: abortController.signal });
}

export function getEstimatedPrice(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/price`, data);
}

export function uploadMedia(id, data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/media`, data);
}

export function deleteMedia(id, data) {
  return http.delete(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/media`, { data });
}

export function log(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/log`, data);
}

export function assignedToAccepted(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/revert/accept`);
}

export function inTransitToAssigned(id, logistic = {}) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/revert/assign`, logistic);
}

export function inProgressToInTransit(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/revert/transit`);
}

export function visitEndToVisitStart(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/revert/visitEnd`);
}

export function completedToVisitEnd(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/revert/completed`);
}

export function sendBookingConfirmation(id, data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/notify/booking-confirmation`, data);
}

export function sendAssignNotification(id) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/notify/assignment`);
}

export function sendRescheduleNotification(id) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/notify/reschedule`);
}

export function addCashCollection(id, data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/payment/add`, data);
}

export function removeCashCollections(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/payment/remove`);
}

export function dischargeCompletedCard(data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/services/discharge`, data);
}

export function getTasks(id, data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/tasks`);
  if (data) uri.addSearch(data);
  return http.get(uri.toString());
}

export function attachInvoiceNo(id, data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/invoice`);
  if (data) uri.addSearch(data);
  return http.put(uri.toString());
}

export function getLogisticData(data) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/delivery/tracking`);
  uri.addSearch(data);
  return http.get(uri.toString());
}

export function chargeService(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/charge`, data);
}

export function acknowledge(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/acknowledge`);
}

export function sendPaymentReminder(id) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/service/${id}/notify/billing-reminder`);
}
