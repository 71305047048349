import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import { Button, Divider, Grid, IconButton, Link, MenuItem, Select, Typography } from "@mui/material";
import { default as MUITooltip } from "@mui/material/Tooltip";
import clsx from "clsx";
import ImageViewer from "components/global/imageViewer";
import AttachmentIcon from "components/icons/attachment";
import HomeVisitIcon from "components/icons/homeVisitIcon";
import LocationListIcon from "components/icons/locationListIcon";
import LoadingSection from "components/loadingSection";
import {
  CheckGreen,
  CheckGrey,
  Description,
  Folder,
  Location,
  Patient,
  Pencil,
  Person,
  Provider2,
  X,
} from "components/oldDesignAssets/icons";
import { format, formatDistance } from "date-fns";
import useUsmsUser from "hooks/useUsmsUser";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  assignRequest,
  completeDeliveryRequest,
  dischargeRequest,
  getLogisticData,
  getRequest,
  transitRequest,
} from "services/requestService";
import { requestStore } from "stores/modalManager";
import { store } from "stores/storeManager";
import { getAvailablePaymentMethods } from "utils/getAvailablePaymentMethods";
import { getFormattedAddress } from "utils/globalUtils";
import notify from "utils/notify";
import Avatar from "../avatar";
import Footer from "../footer";
import Header from "../header";
import { BluedotDash, Building, FreeDollar } from "../icons";
import Input from "../input";
import StatusTag from "../statusTag";
import Tag from "../tag";
import Tooltip from "../tooltip";
import UserVerificationID from "../userVerificationID";
import ActivityLog from "./activityLog";
import ConfirmCancellation from "./confirmCancellation";
import EditRequest from "./editRequest";
import ProviderAssignment from "./providerAssignment";
import Request from "./request";
import Tracking from "./tracking";

const maskStopId = {
  Lalamove: id => Number(id) + 1,
  Borzo: id => id || "00000",
};

export default function DeliveryRequest() {
  const { request, hideModal } = requestStore.useState(s => ({
    request: { ...s.request, ...s.unsavedChanges },
    hideModal: s.hideModal,
  }));

  const { language, country } = store.useState(s => ({
    language: s.language,
    country: s.country,
  }));
  const alpha2 = country.alpha2;

  const { free, selectedPaymentMethod: paymentMethod } = request.billing;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [logisticStops, setLogisticStops] = useState([]);

  const { t = () => "" } = useTranslation();
  const serviceStatus = t(`${language}.serviceStatus`);
  const serviceTitle = t(`${language}.serviceTitleShort`);
  const thirdPartyDelivery = t(`${language}.thirdPartyDelivery`);

  const payer = useUsmsUser(request.owner || request.user, alpha2);
  const patient = useUsmsUser(request.user, alpha2);
  const mainProvider = request.providers.find(p => p.owner);
  const jarvisNote = request.records.find(r => r.type === "jarvisNotes")?.data.jarvisNotes[0].message;

  const logisticCompany = useMemo(() => request.providers.find(p => p.owner)?.user?.company, [request.providers]);

  const handleChangeOrderId = useCallback(
    logisticOrderId => {
      setError({ ...error, logisticOrderId: false });
      setLogisticStops([]);
      requestStore.update(s => {
        s.unsavedChanges.logistic = { ...request.logistic, orderId: logisticOrderId, stopId: undefined };
      });
    },
    [error, request.logistic],
  );

  const handleChangeStop = useCallback(
    event => {
      setError({ ...error, logisticOrderId: false });
      requestStore.update(s => {
        s.unsavedChanges.logistic = { ...request.logistic, stopId: event.target.value };
      });
    },
    [error, request.logistic],
  );

  const pullLogisticData = useCallback(async () => {
    try {
      const { data } = await getLogisticData({
        company: logisticCompany,
        serviceRef: request._id,
        orderId: request.logistic?.orderId,
      });
      setLogisticStops(data.service.stops);
    } catch (error) {
      notify(error.data, "error");
    }
  }, [logisticCompany, request._id, request.logistic?.orderId]);

  const validateAcceptedRequest = useCallback(() => {
    const err = {
      logisticOrderId: !!logisticCompany && !request.logistic?.orderId,
    };
    setError(err);
    return !err.logisticOrderId;
  }, [logisticCompany, request.logistic?.orderId]);

  const handleAcceptedToAssigned = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!validateAcceptedRequest()) return;
      const result = await assignRequest(request._id, {
        providers: request.providers.map(p => ({ userRef: p.user._id, owner: p.owner, adhoc: p.adhoc })),
        scheduleStart: request.schedule.start,
        scheduleEnd: request.schedule.end,
        logisticOrderId: request.logistic.orderId,
        logisticStopId: request.logistic.stopId,
      });
      notify(result.data?.message);
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      notify(error.data, "error");
    } finally {
      setIsLoading(false);
    }
  }, [
    request._id,
    request.logistic.orderId,
    request.logistic.stopId,
    request.providers,
    request.schedule.end,
    request.schedule.start,
    validateAcceptedRequest,
  ]);

  const handleAssignedToInTransit = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await transitRequest(request._id);
      notify(result.data?.message);
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      notify(`${error.data}`, "error");
    } finally {
      setIsLoading(false);
    }
  }, [request._id]);

  const showTracking = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <Tracking />;
    });
  }, []);

  const handleInTransitToCompleted = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await completeDeliveryRequest(request._id);
      notify(result.data?.message);
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      notify(`${error.data}`, "error");
    } finally {
      setIsLoading(false);
    }
  }, [request._id]);

  const handleCompletedToClosed = useCallback(async () => {
    try {
      setIsLoading(true);
      await dischargeRequest(request._id);
      notify("Request closed");
      requestStore.update(s => {
        s.request.status = "discharged";
      });
      hideModal();
    } catch (e) {
      notify(`${e.data}`, "error");
    } finally {
      setIsLoading(false);
    }
  }, [hideModal, request._id]);

  const handleConfirmCancellation = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <ConfirmCancellation />;
    });
  }, []);

  const buttons = useMemo(
    () => ({
      accepted: {
        text: "Set Assigned",
        action: () => handleAcceptedToAssigned(),
      },
      assigned: { text: "Set In Transit", action: () => handleAssignedToInTransit() },
      inTransit: { text: "Complete", action: () => handleInTransitToCompleted() },
      completed: { text: "Close Request", action: () => handleCompletedToClosed() },
      cancelled: { text: "Closed", action: () => handleConfirmCancellation() },
    }),
    [
      handleAcceptedToAssigned,
      handleAssignedToInTransit,
      handleCompletedToClosed,
      handleConfirmCancellation,
      handleInTransitToCompleted,
    ],
  );

  const showLog = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <ActivityLog />;
    });
  }, []);

  const handleClickParentRequest = useCallback(
    id => async () => {
      requestStore.update(s => {
        s.componentsToRender = <LoadingSection className="modalContent">Loading request data</LoadingSection>;
      });

      try {
        const response = await getRequest(id);
        requestStore.update(s => {
          s.request = response.data.service;
          s.unsavedChanges = {
            billing: { ...s.request.billing },
            schedule: { ...s.request.schedule },
            providers: [...s.request.providers],
            isDirty: {},
          };
          s.componentsToRender = <Request />;
        });
      } catch (error) {
        console.log(error);
        notify(error.data, "error");
      }
    },
    [],
  );

  const onClickEditRequest = useCallback(
    async e => {
      e.preventDefault();

      requestStore.update(s => {
        s.unsavedChanges = {
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          availablePaymentMethods: getAvailablePaymentMethods({
            country,
            user: s.request.user,
            owner: s.request.owner,
          }),
          isDirty: {},
        };
        s.componentsToRender = <EditRequest />;
      });
    },
    [country],
  );

  const showProviderAssignment = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <ProviderAssignment origin="request" />;
    });
  }, []);

  const onClickButton = useCallback(async () => {
    await buttons[request.status].action();
  }, [buttons, request.status]);

  const renderStopValue = useCallback(
    value => (
      <>
        <Typography variant="caption" color="text.secondary">
          Stops:{" "}
        </Typography>
        <Typography variant="caption">{!!value ? maskStopId[logisticCompany](value) : "-"}</Typography>
      </>
    ),
    [logisticCompany],
  );

  return (
    <div className="modalContent requestUM">
      <Header>
        <Grid container width="50%" alignItems="flex-end">
          <Typography mr={1} fontSize="18px" color="#3257B6">
            {serviceTitle[request.type]}
          </Typography>
          <Typography variant="caption" color="#6C6C6C">
            S{request.id}
          </Typography>
        </Grid>
        <StatusTag className={`${request.status}`} statusName={`${request.status}`}>
          {serviceStatus[request.status]}
        </StatusTag>
        {["assigned", "accepted", "inTransit", "completed"].includes(request.status) && (
          <IconButton size="small" edge="end" aria-label="edit-request" onClick={onClickEditRequest} disableRipple>
            <Pencil sx={{ color: "#979797" }} />
          </IconButton>
        )}
        <X className="close" onClick={hideModal} />
      </Header>
      <Grid px={2} mt={-2.5} pb={2}>
        <Typography variant="caption">Med Delivery</Typography>
      </Grid>

      <Divider />

      <div className="content">
        {!!request.parent.id && (
          <>
            <Grid container p={2} wrap="nowrap">
              <Grid item xs={1.25} container alignItems="center">
                <HomeVisitIcon />
              </Grid>
              <Grid item xs container direction="column">
                <Typography
                  role="link"
                  variant="caption"
                  color="wildTide.500"
                  fontWeight={600}
                  sx={{ cursor: "pointer" }}
                  data-testid="parentLink"
                  onClick={handleClickParentRequest(request.parent._id)}>
                  {serviceTitle[request.parent.type]} - S{request.parent.id}
                </Typography>
                <Typography variant="caption">
                  {!!request.parent?.schedule?.start &&
                    !!request.parent?.schedule?.end &&
                    `${format(new Date(request.parent.schedule.start), "EEEE dd MMM · HH:mm -")}${format(
                      new Date(request.parent.schedule.end),
                      " HH:mm",
                    )}`}
                </Typography>
              </Grid>
            </Grid>

            <Divider />
          </>
        )}

        <Grid container p={2} direction="column">
          <Typography variant="caption" fontWeight={600}>
            {format(new Date(request.schedule.start), "EEEE dd MMM · HH:mm")}
          </Typography>
          <Typography variant="caption">{getFormattedAddress(request.schedule.destinationAddress)}</Typography>
          <Grid item container alignItems="center" pt={2}>
            <Grid item xs={1.25} container alignItems="center">
              <Grid item ml={0.75} mt={0.5}>
                <Provider2 />
              </Grid>
            </Grid>
            {request.status === "accepted" && !request.providers.length ? (
              <Button
                variant="contained"
                color="secondary"
                sx={{ minWidth: "209px", fontWeight: 600 }}
                onClick={showProviderAssignment}>
                Assign Provider
              </Button>
            ) : (
              <div className="providerInfo">
                {request.providers.map(p => (
                  <div className="providerInfoItem" key={p.user._id}>
                    <Avatar userData={p.user} />
                    <div className="providerDetail">
                      <div className="name">
                        <div className="fullName">{p.user.name}</div>
                        {p.owner ? <div className="primaryInfo">Primary</div> : ""}
                      </div>
                      <div className="type">{p.user.company ? thirdPartyDelivery : p.user.specialty}</div>
                    </div>
                    <div className="seenStatus">{p.seen?.status ? <CheckGreen /> : <CheckGrey />}</div>
                  </div>
                ))}
              </div>
            )}
          </Grid>
        </Grid>

        <Divider />

        <Grid container p={2} direction="column" gap={1}>
          <Grid item container>
            <Grid item xs={1.25} container>
              <Grid item ml={1.75}>
                <BluedotDash />
              </Grid>
            </Grid>
            <Grid item xs container wrap="nowrap">
              <Typography variant="caption">{getFormattedAddress(request.schedule.requestedAddress)}</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={1.25} container sx={{ svg: { path: { fill: "#FF1F4A" } } }}>
              <Grid item ml={0.8}>
                <Location />
              </Grid>
            </Grid>
            <Grid item xs container wrap="nowrap">
              <Typography variant="caption" mt={0.25}>
                {getFormattedAddress(request.schedule.destinationAddress)}
              </Typography>
            </Grid>
          </Grid>
          {!logisticCompany && request.status === "inTransit" && (
            <Grid item container>
              <Grid item xs={1.25} container>
                <Grid item ml={0.8}>
                  <LocationListIcon />
                </Grid>
              </Grid>
              <Grid item xs={3} container alignItems="center">
                {mainProvider?.itinerary?.lastPosition?.lat && request.schedule.destinationAddress.coordinates?.lat ? (
                  <Link onClick={() => showTracking()} color="#126EF8" fontWeight={600} alignItems="center">
                    Tracking Link
                  </Link>
                ) : (
                  "Tracking Unavailable"
                )}
              </Grid>
            </Grid>
          )}
          {!!logisticCompany && (
            <Grid item container>
              <Grid item xs={1.25} container>
                <Grid item ml={0.8}>
                  <LocationListIcon />
                </Grid>
              </Grid>
              <Grid
                item
                xs
                container
                sx={{
                  input: { borderTopRightRadius: "0 !important", borderBottomRightRadius: "0 !important" },
                }}
                wrap="nowrap">
                <Grid item xs={4}>
                  <Input
                    value={request.logistic?.orderId || ""}
                    onChange={handleChangeOrderId}
                    disabled={request.status !== "accepted"}
                    appendButton={
                      <IconButton
                        role="button"
                        aria-label="refresh invoice details"
                        onClick={pullLogisticData}
                        disabled={request.status !== "accepted"}
                        color="secondary"
                        size="small"
                        sx={{ position: "absolute", top: -2, right: 0 }}>
                        <SyncIcon />
                      </IconButton>
                    }
                  />
                </Grid>
                <Grid item xs container alignItems="center" gap={2}>
                  <Select
                    size="small"
                    value={request.logistic?.stopId || ""}
                    onChange={handleChangeStop}
                    renderValue={renderStopValue}
                    disabled={request.status !== "accepted" || logisticStops.length === 0}
                    sx={{
                      height: 30,
                      minHeight: "unset",
                      fieldset: { borderColor: "#E7EDF4", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
                      "&.Mui-focused, &.Mui-disabled, :hover": {
                        fieldset: {
                          borderColor: "#E7EDF4 !important",
                          borderWidth: "1px !important",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                      },
                      "&.Mui-disabled": {
                        cursor: "not-allowed !important",
                      },
                    }}
                    displayEmpty>
                    {logisticStops?.map(s => (
                      <MenuItem key={s.id} value={s.id} disabled={!s.available}>
                        <Grid container direction="row" wrap="nowrap" gap={2} alignItems="center">
                          <Typography variant="caption" fontWeight={600}>
                            {maskStopId[logisticCompany](s.id)}
                          </Typography>
                          <Grid item container direction="column">
                            <Typography variant="caption" fontWeight={600}>
                              {s.address}
                            </Typography>
                            <Typography variant="caption" fontWeight={600} color="text.secondary">
                              {s.name} | {s.phone}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                  {error.logisticOrderId && (
                    <Grid item mt={0.5}>
                      <Tooltip id="tooltip" text="Required field" />
                    </Grid>
                  )}
                  {request.logistic?.trackingUrl && request.status === "inTransit" && (
                    <Link
                      href={request.logistic?.trackingUrl}
                      color="#126EF8"
                      target="_blank"
                      rel="noreferrer"
                      fontWeight={600}
                      width="fit-content">
                      Tracking Link
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Divider />

        <Grid container p={2}>
          <Grid item xs={1.25} container>
            <Grid item ml={0.75}>
              <Person />
            </Grid>
          </Grid>
          <Grid item xs container direction="column">
            <Typography variant="caption" fontWeight={600} mt={0.25} width="fit-content">
              <UserVerificationID user={payer} />
            </Typography>
            {payer.phoneNumbers?.length && payer.phoneNumbers[0] && (
              <Link
                href={`tel:${payer.phoneNumbers[0].ext}${payer.phoneNumbers[0].number}`}
                color="#126EF8"
                target="_blank"
                rel="noreferrer"
                width="fit-content">
                {payer.phoneNumbers[0].ext} {payer.phoneNumbers[0].number}
              </Link>
            )}
            {payer.email.address && (
              <Link
                href={`mailto:${payer.email.address}`}
                color="#6C6C6C"
                target="_blank"
                rel="noreferrer"
                width="fit-content">
                {payer.email.address}
              </Link>
            )}
          </Grid>
          <Grid item xs container direction="row-reverse">
            <PaymentInfo free={free} paymentMethod={paymentMethod} business={request.business} />
          </Grid>
        </Grid>

        <Divider />

        <Grid container p={2} gap={1}>
          <Grid item container alignItems="center">
            <Grid item xs={1.25} container>
              <Grid item ml={1}>
                <Patient />
              </Grid>
            </Grid>
            <Typography variant="caption">
              <UserVerificationID user={patient} />
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={1.25} container>
              <Grid item ml={1}>
                <Folder />
              </Grid>
            </Grid>
            <Typography variant="caption" textTransform="capitalize">
              {request.case.type} {request.case.program?.title || ""} {request.case.business?.tag || ""}{" "}
              {`C-${request.case.id}`}
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={1.25} container>
              <Grid item ml={1}>
                <Description />
              </Grid>
            </Grid>
            <Grid item xs={10.75}>
              <Typography variant="caption" whiteSpace="pre-wrap">
                {jarvisNote || "N/A"}
              </Typography>
            </Grid>
          </Grid>
          {request.logistic?.pod && (
            <Grid item container alignItems="center">
              <Grid item xs={1.25} container>
                <Grid item ml={1}>
                  <AttachmentIcon />
                </Grid>
              </Grid>
              <ImageViewer
                src={request.logistic.pod.replace("http://", "https://")}
                alt="proof of delivery"
                width={64}
                previewHeight={450}
                auth={!request.providers?.[0]?.user.company}
              />
            </Grid>
          )}
        </Grid>
      </div>

      <Footer>
        <Divider />
        <Grid p={2}>
          {request.status === "cancelled" && (
            <div className="caption">
              Canceled by: <span className="redText">{request.cancelled.by?.name}</span>
              <br />
              Reason:
              <span className="redText">{` ${request.cancelled.reason} (${formatDistance(
                new Date(request.createdAt),
                new Date(request.cancelled.date),
              )} after creation)`}</span>
            </div>
          )}

          <div className="actions">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs container wrap="nowrap">
                <MUITooltip title="Activity Log" followCursor placement="top">
                  <IconButton role="button" aria-label="show-log-button" onClick={showLog} edge="end" size="small">
                    <HistoryOutlinedIcon htmlColor="#979797" sx={{ "&:hover": { color: "#00c3b5" } }} />
                  </IconButton>
                </MUITooltip>
              </Grid>

              <Grid
                item
                xs={8}
                container
                className="rightActions"
                alignItems="center"
                wrap="nowrap"
                justifyContent="flex-end">
                {request.status !== "discharged" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontWeight: 400,
                      height: "34px",
                      minHeight: "unset",
                      "&.Mui-disabled": {
                        color: "#3ebebc",
                        backgroundColor: "white",
                        border: "1px solid #d5f2f0;",
                        WebkitBoxShadow: "0 1px 14px 0 rgb(62 67 72 / 15%)",
                      },
                    }}
                    disabled={isLoading || (request.status === "accepted" && !request.providers?.length)}
                    onClick={onClickButton}>
                    {buttons[request.status]?.text}
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Footer>
    </div>
  );
}

const PaymentInfo = ({ free, paymentMethod, business }) => {
  return (
    <div className="userBilling">
      <span className={clsx("accountNumber", { invalid: paymentMethod?.origin !== "business" && !free })}>
        <CaptionText free={free} paymentMethod={paymentMethod} />
      </span>
      {business && (
        <Grid container alignItems="center">
          <Building />
          <Typography fontSize={12} fontWeight={500} color="#6c6c6c">
            {business.tag}
          </Typography>
        </Grid>
      )}
    </div>
  );
};

const CaptionText = ({ free, paymentMethod }) => {
  if (free)
    return (
      <div className="freeCaption">
        <FreeDollar />
        <div className="content">
          <span className="main">No Charge</span>
          <span className="action">Do not collect</span>
        </div>
      </div>
    );

  if (paymentMethod?.origin === "business")
    return (
      <div className="corporateCaption">
        <Building />
        <div className="content">
          <span className="main">
            <Tag>{paymentMethod.name}</Tag>
          </span>
          <span className="action">Do not collect</span>
        </div>
      </div>
    );

  return "Payment method missing or invalid";
};
